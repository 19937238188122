<template>


 
      <div class="week" :ref="'week_' + props.weekId">
         <div class="cal">{{ props.days }}</div>
        <template v-if="props.days">
          Week {{ props.days }}. 
        </template>
        {{ $constants.typeFazaShort[props.faza] ? $t($constants.typeFaza[props.faza]) : '' }}
        <!-- <i class="icon-play" v-if="props.isVideo"></i> -->
 
      </div>
 
 
 
  
</template>

<script setup>
 
 
var props = defineProps({
  days: {
    type: Number || String,
    required: false
  },
  // isVideo: {
  //   type: Boolean || Number,
  //   required: false
  // },
  faza: {
    type: Number,
    required: true
  },
  weekId: {
    type: Number,
    required: true
  }
})

</script>

<style scoped>
 

 .week{
  font-weight: bold;
    padding-bottom: 4px;
    z-index: 1;
    background-color: #ffffffe6;
    border-radius: 5px;
    padding: 4px 12px;
    position: sticky;
    top: 60px;
    width: -moz-fit-content;
    width: fit-content;
    /* margin-top: 12px; */
    margin-bottom: 5px;
    font-size: 1rem;
    margin-left: -12px;
}
.is-modal .week{
  top:10px;
}


.cal{
  font-size: 0.6rem;
    font-weight: bold;
    color: var(--un-text-color-gray);
    background-image: url(/images/calendar/1.svg);
    aspect-ratio: 1 / 1;
    width: 22px;
    height: 22px;
    display: inline-block;
    text-align: center;
    line-height: 29px;
}
</style>
