<template>
 
  <div class="galpan">

    <WeekwideViewWeek
      v-for="(week, index) in props.diary.items_week"    
      :week="week"
      :diary="props.diary"
      :active-week="props.activeWeek"
      :ref="weekEl => { if (weekEl) setWeekRef(weekEl.el, index) }"
      />
 
    
  </div>
 
  
</template>

<script setup>

import { useIntersectionObserver, watchArray } from '@vueuse/core'

var props = defineProps({
  diary: {
    type: Object,
    required: true
  }, 
  activeWeek: {
    type: Object,
    required: false
  },
  comments: {
    type: Object,
    required: false
  },
  isModal: {
    type: Boolean || Number,
    required: false
  }
})
const emits = defineEmits(['interactWeek'])
const weekRefs = ref([])
const observers = []
const isObserverDisabled = ref(false);
const isScrolledViewWeek = ref(false);

var lightboxShow = ref(false);
var lightboxId = ref(null);

const setWeekRef = (el, index) => {
  weekRefs.value[index] = el
}


const lightboxPhotos = computed(() => {
  var items = [];


  for(var i of props.diary.items_week){
    for(var p of i.items_photo){
      let photo = {};
      if(p.is_video){
        photo.type = 'iframe';
        photo.iframe = '/video/' + p.video_id;
      }          
      photo.id = p.id;
      photo.width = p.width;
      photo.height = p.height;
      photo.base = p.size_big;
      photo.zoom = p.size_big;
      photo.caption = p.comment;
      items.push(photo);        
    }
  }
  return items;

  // for(var i of props.data.items_photo){
  //   let photo = {};
  //   if(i.is_video){
  //     photo.type = 'iframe';
  //     photo.iframe = '/video/' + i.video_id;
  //   }          
  //   photo.base = i.size_big;
  //   photo.zoom = i.size_big;
  //   photo.caption = i.comment;
  //   items.push(photo);        
  // }
  // return items;
})

const openGallery = function(key){
  lightboxShow.value = true;
  lightboxId.value = key;
}


onMounted(() => {
  weekRefs.value.forEach((weekEl, index) => {
    const stopObserver = useIntersectionObserver(
      // Передаём функцию, возвращающую DOM-элемент
      () => weekRefs.value[index],
      ([{ isIntersecting }]) => {
        if (isObserverDisabled.value) return;
        if (isIntersecting) {
          emits('interactWeek', props.diary.items_week[index])
          isScrolledViewWeek.value = true;
          setTimeout(() => isScrolledViewWeek.value = false, 1000);
        }
      },
      { 
        root: null,
        rootMargin: '0px 0px -50% 0px', 
        threshold: 0.3
      }
    )
    observers.push(stopObserver) // stopObserver - это функция остановки наблюдения
  })
})

onBeforeUnmount(() => {
  observers.forEach(observer => observer.stop())
})

 
watchArray([props], ([new_props]) => {
  // console.log('activeWeek gallery', new_props.activeWeek);
  isObserverDisabled.value = true; 

  props.diary.items_week.forEach((w,i) => {
    
    if(isScrolledViewWeek.value) return;

    if(w.id == new_props.activeWeek.id){
      // console.log('watch props weeks', w,i);


      if(props.isModal){
        let scrollableContainer = useModalsDiaryModalRef().value;
        console.log('scrollableContainer', scrollableContainer);
        scrollableContainer.scrollTo({
          top: weekRefs.value[i].offsetTop + 160, // вычитаем 40 пикселей
          behavior: 'smooth'
        });
      }else{
        const elementRect = weekRefs.value[i].getBoundingClientRect();
        const elementTop = elementRect.top + window.pageYOffset;
        window.scrollTo({
          top: elementTop - 50, // вычитаем 40 пикселей
          behavior: 'smooth'
        });
      }
 
    }
  });

  setTimeout(() => {
    isObserverDisabled.value = false; 
  }, 20);

}, {deep: true})

</script>

<style scoped>
 
.galpan{

  display: flex;
  flex-direction: column;
  gap: 3rem;
  position: relative;
  width: 100%;
  margin-bottom: 7rem;
}

  
 

 

</style>
