<template>



    <div 
      ref="root"
      :data-id="props.week.id"
      :class="'day_item day_faza_' + week.faza + '  post_day_' + week.id + (activeWeek == week.id ? ' active ' : '')"                                               
      >
      

      <WeekwideViewHarvestTitle
        v-if="props.week.item_harvest"
        :harvest-name="props.week.item_harvest?.item_review_seed.item_product.name"
        :harvest-brand="props.week.item_harvest?.item_review_seed.item_brand.name"
        /> 


      <WeekwideViewWeekName 
        :days="props.week.days"
        :faza="props.week.faza"
        :week-id="props.week.id"
        />
 



      <DiarywideViewGalleryWeeksSlider 
        :week="week" 
        :diary="diary"
        />

 

      <WeekwideViewCommentSetupBig
        v-if="props.week.faza == 2"
        :setup="props.week.props"
        @click="setupShow = true"     
        /> 
      <WeekwideViewCommentSetup
        v-else
        :setup="props.week.props"    
        @click="setupShow = true" 
        /> 

      <WeekwideViewNutrientsSetup
        :nutrients="props.week.item_germination?.items_nutrient || week.item_vegetation?.items_nutrient || week.item_flowering?.items_nutrient"
        @click="nutrientsShow = true"
        /> 




      <WeekwideViewReview
        :data="props.week.item_harvest"
        #click="reviewShow = true"
        /> 


      <!-- <WeekwideViewComment 
        :week="week" 
        :weeks="diary.items_week"
        :user="diary.item_user"
        :comments="props.comments"
        @click="commentShow = true"
        /> -->


      <WeekwideViewAuthorComment
        :week="props.week"
        :item-user="diary.item_user"
        :week-id="props.week.id"
        :comments="props.comments"
        @click="commentShow = true"
        />



      
      <WeekwideViewGermMethod 
        :item-method="props.week.item_germination?.germ_method" 
        />

      <WeekwideViewMethods 
        :items-method="props.week.item_vegetation?.items_method || props.week.item_flowering?.items_method" 
        />
 

    </div>



    <!--  -->

    <ClientOnly>
      <Teleport to="#teleport"> 

        <CommentWidgetFlowWeek
          v-if="commentShow"
          :title="$t('journal_view_post_comments')"
          :type="'diary'"
          :content="props.week.id"
          :week-text="props.week.text"
          :week-author="props.diary.item_user"
          @close="commentShow = false"
          />              

            
        <WeekwideViewSetupFlow
          v-if="setupShow"
          :title="'Setup'"
          :week="props.week"
          @close="setupShow = false"
          />

            
        <WeekwideViewReviewFlow
          v-if="reviewShow"
          :title="'Review'"
          :week="props.week"
          @close="reviewShow = false"
          />


        <WeekwideViewNutrientsFlow
          v-if="nutrientsShow"
          :title="'Nutrients'"
          :week-nutrients="props.week.item_germination?.items_nutrient || props.week.item_vegetation?.items_nutrient || props.week.item_flowering?.items_nutrient"
          @close="nutrientsShow = false"
          />
          
      </Teleport>
    </ClientOnly>
 
  
</template>

<script setup>
 
 
var props = defineProps({
  diary: {
    type: Object,
    required: true
  }, 
  week: {
    type: Object,
    required: false
  },
  activeWeek: {
    type: Object,
    required: false
  },
})

const root = ref(null)
const commentShow = ref(false);
const nutrientsShow = ref(false);
const setupShow = ref(false);
const reviewShow = ref(false);

defineExpose({
  el: root
})

</script>

<style scoped>
 
</style>
